import consumer from "./consumer"

consumer.subscriptions.create({ channel: "ChatChannel" }, {
  received(data) {
    let roomId = data['room_id'];

    if ($(`#${roomId}`).length > 0) {
      this.appendLine(data);
    }

    if ($(`#subscriber_${roomId}`).length > 0) {
      $(`#subscriber_${roomId} .js-chat-user-last-message`).html(data['message_body']);
      $(`#subscriber_${roomId} #unread_messages_counter`).removeClass('d-none');
    }

    if (!$('#chat_icon_container').hasClass('d-none')) {
      let receiverIds = data['receiver_ids'];
      let uId         = $('.js-chat-icon-container').data('userId');

      if (receiverIds.includes(uId)) {
        $('#chat_floating_counter').removeClass('d-none');
      }
    }
  },

  appendLine(data) {
    let messageBody   = data['message_body'];
    let userName      = data['user_name'];
    let roomId        = data['room_id'];
    let currentUserId = $(`#${roomId}`).data('user-id');
    let userId        = data['user_id'];
    let sentAt        = data['sent_at'];

    let html = `<div class="message-item ${userId == currentUserId ? 'justify-content-end' : 'justify-content-start'}">`;
    html    += "<div class='d-flex justify-content-start'>";
    if (userId != currentUserId && data['image_url']) {
      html  += `<img src="${data['image_url']}", class='chat-message-user-avatar' />`;
    }
    html    += `<div class="chat-message-body col ${userId == currentUserId ? 'current-user-message-body' : ''}">`;
    if (userId != currentUserId) {
      html  += `<p class='user-name'>${userName}</p>`;
    }
    html    += `<p class="user-message-body">${messageBody}</p>`;
    html    += `<p class="user-message-sent-at">${sentAt}</p>`;
    html    += '</div>';
    html    += '</div>';
    html    += '</div>';

    $(`#${roomId}`).append(html);
    $(`#${roomId}`).scrollTop($(`#${roomId}`)[0].scrollHeight);
  }
});
