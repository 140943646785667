$(window).on('load', function () {
  $('body').on('click', '.js-event-placeholer', function() {
    var placeholer = $(this).data("placeholder");
    $('#custom_event_email_body').val($('#custom_event_email_body').val() + placeholer);
  });

  $('body').on('click', '#add_custom_event_email_user', function(e) {
    e.preventDefault();
    var userEmail = $('#custom_event_email_new_user_email').val();
    if (userEmail == '') {
      alert('Enter correct email address first');
    } else {
      var newElement = "<div>";
      newElement    += `<input type='checkbox' name='user_emails[]' id='${userEmail}' value='${userEmail}'>`;
      newElement    += `<label for='${userEmail}'>${userEmail}</label>`;
      newElement    += "</div>";

      $('#event_users_container_custom_email').append(newElement);
    }
  });

  $('body').on('click', '.js-match-action-interested', function() {
    var matcherId = $(this).data("matcher-id");
    var userId    = $(this).data("user-id");
    if($(this).hasClass('responded')) {
      $.ajax({
        type: 'PUT',
        url: `/event_user_matchers/${matcherId}/not_interested_to_chat`,
        data: { u_id: userId }
      });
    } else {
      $.ajax({
        type: 'PUT',
        url: `/event_user_matchers/${matcherId}/interested_to_chat`,
        data: { u_id: userId }
      });
    }
  });
});