$(window).on('load', function () {
  $(document).scroll(function(){
    if($(this).scrollTop() >= 50) {
      $('.js-navbar').addClass('scrolled');
    } else {
      $('.js-navbar').removeClass('scrolled');
    }
  });

  setTimeout(function(){
    $('.js-flash-messages-wrapper').css("top", "13%");
  }, 500);

  setTimeout(function(){
    $("#flash-notice").fadeOut();
  }, 5000);

  setTimeout(function(){
    $("#flash-error").fadeOut();
  }, 5000);

  setTimeout(function(){
    $("#flash-10-notice").fadeOut();
  }, 10000);

  $("#user_birthday_picker").birthdayPicker({
    minAge: 18,
    inputName: 'dob',
    dateFormat: 'littleEndian'
  });

  $('body').on('click', '.js-forward-slider-icon', function() {
    var target  = $(this).data('slider-container');
    var leftPos = $(target).scrollLeft();
    var scroll  = $(target).width();
    $(target).animate({ scrollLeft: leftPos + scroll }, 500);

    leftPos        = leftPos + scroll;
    var totalWidth = $(target)[0].scrollWidth;
    $(this).toggleClass('collapse', scroll + leftPos + 20 >= totalWidth);

    var backwardIcon = $(this).siblings('.js-backward-slider-icon')[0];
    $(backwardIcon).toggleClass('collapse', leftPos <= 0);
  });

  $('body').on('click', '.js-load-more-icon', function() {
    var excludeUserIds = $(this).data('exclude-user-ids');
    var linkPath       = $(this).data('link-path');
    var params         = { exclude_user_ids: excludeUserIds };

    if($(this).data('container-type') == 'find_singles') {
      params['age_from']     = $('#age_from').val();
      params['age_to']       = $('#age_to').val();
      params['user_regions'] = $('.js-user-regions:checked').map(function() { return this.value; }).get();
      params['user_high_match_value_filter'] = $('#user_high_match_value_filter_true').is(':checked');
      params['user_visited_profiles']        = $('#user_visited_profiles_true').is(':checked');
      params['user_likes_given']             = $('#user_likes_given_true').is(':checked');
    }

    $.ajax ({
      method: 'GET',
      url: linkPath,
      data: params
    });
  });

  $('body').on('click', '.js-backward-slider-icon', function() {
    var target  = $(this).data('slider-container');
    var leftPos = $(target).scrollLeft();
    var scroll  = $(target).width();
    $(target).animate({ scrollLeft: leftPos - scroll }, 500);

    leftPos = leftPos - scroll;
    $(this).toggleClass('collapse', leftPos <= 0);

    var totalWidth  = $(target)[0].scrollWidth;
    var forwardIcon = $(this).siblings('.js-forward-slider-icon')[0];
    $(forwardIcon).toggleClass('collapse', scroll + leftPos + 20 >= totalWidth);
  });

  if ($('.js-forward-slider-icon').length > 0) {
    $('.js-forward-slider-icon').each(function() {
      var target = $(this).data('slider-container');
      $(this).toggleClass('collapse', $(target)[0].scrollWidth <= $(target).width());
    });
  }

  $('body').on('click', '.js-free-plan-restriction', function(e) {
    e.preventDefault();

    let message = $(this).data('restrictionMessage');
    alert(message);
  })

  $('body').on('click', '.js-free-plan-restriction-confirmation', function(e) {
    e.preventDefault();

    let message = $(this).data('restrictionMessage');
    let link    = $(this).data('restrictionOutboundLink');
    if(confirm(message)) {
      window.open(link);
    }
  })
});
