$(window).on('load', function () {
  $('body').on('click', '#date_event_withdraw_request', function(e) {
    e.preventDefault();
    if (!$('#date_event_terms_and_conditions_true').is(':checked')) {
      alert($(this).data('errorMessage'));
      return;
    }

    let id = $('#date_event_id').val();

    $.ajax({
      type: 'PUT',
      url: `/date_events/${id}/withdraw_request`,
      params: {}
    })
  });

  initDateEventElements();

  $('body').on('keydown', "#date_event_details_text", function(e) {
    if (e.keyCode == 13) {
      e.preventDefault();
      e.stopPropagation();
      let position      = this.selectionEnd;
      this.value        = this.value.substring(0, position) + '<br>' + this.value.substring(position);
      this.selectionEnd = position;
    }
  });
});

function renderDateTimetext(e, index) {
  // rendering date and time text in the div
  let d  = e.elem.innerHTML.split(', ')[0];
  let t  = e.calendars[0].querySelector('.js-time-element') ? e.calendars[0].querySelector('.js-time-element.active').dataset['time'] : '';
  let dt = `${d}, ab ${t} Uhr`;
  e.elem.innerHTML = dt;

  if (!d.split('>')[1]) {
    $(`#date_event_element_${index}_datetime`).val(`${d}, ${t}`);
  }
}

function renderCustomTextInDiv(e) {
  if (e.elem.dataset.value == '') {
    let html = `<p>${e.elem.dataset['index']}. ${e.elem.dataset['translation']}</p>`;
    html    += '<i class="icon icon-heart"></i>';
    e.elem.innerHTML = html;
  } else {
    e.elem.innerHTML = e.elem.dataset.value;
  }

  $(`.js-date-element-calendar-${e.elem.dataset['index']}`).remove();
}

window.initDateEventElements = function() {
  $('.js-date-event-calentim-element').calentim(
    {
      showTimePickers: false,
      format: "L",
      singleDate: true,
      startEmpty: true,
      calendarCount: 1,
      showHeader: false,
      showFooter: false,
      showOn:"right",
      autoAlign:true,
      minDate: moment().add(7, "days").startOf("day"),
      locale: 'de',
      onBeforeInit: function(e) {
        // rendering custom text in div tag
        renderCustomTextInDiv(e);
      },
      oninit: function(e) {
        // rendering custom text in div tag
        renderCustomTextInDiv(e);
      },
      onafterhide: function(e) {
        let index = e.elem.dataset['index'];
        renderDateTimetext(e, index);
      },
      onaftershow: function(e) {
        let index = e.elem.dataset['index'];
        renderDateTimetext(e, index);
      },
      ondraw: function(e) {
        let index = e.elem.dataset['index'];
        let t     = e.elem.innerHTML.split(', ')[1];
        if(t) {
          t = t.split(' ')[1];
        } else {
          t = '18:00';
        }

        e.calendars[0].querySelector('.calentim-calendar').classList.add(`js-date-element-calendar-${index}`);
        if ($(`.js-date-element-calendar-${index} > .js-custom-time-container`).length == 0) {
          let ele = "<div class='custom-time-container js-custom-time-container'>" +
                    "<div class='d-flex justify-content-start'>" +
                      `<div class="time-element js-time-element ${t == '18:00' ? 'active' : ''}" data-time='18:00' data-parent-index=${index}>` +
                        "18:00" +
                      "</div>" +
                      `<div class="time-element js-time-element ${t == '18:30' ? 'active' : ''}" data-time='18:30' data-parent-index=${index}>` +
                        "18:30" +
                      "</div>" +
                      `<div class="time-element js-time-element ${t == '19:00' ? 'active' : ''}" data-time='19:00' data-parent-index=${index}>` +
                        "19:00" +
                      "</div>" +
                      `<div class="time-element js-time-element ${t == '19:30' ? 'active' : ''}" data-time='19:30' data-parent-index=${index}>` +
                        "19:30" +
                      "</div>" +
                    "</div>" +
                  "</div>";
          $(`.js-date-element-calendar-${index}`).append(ele);
        }

        // on click listners for custom time pills
        $(`.js-date-element-calendar-${index} .js-time-element`).on('click', function(e) {
          let i = $(this).data('parent-index');
          $(`.js-date-element-calendar-${i} .js-time-element`).removeClass('active');
          $(this).addClass('active');
        });
        renderDateTimetext(e, index);
      },
    }
  );
}