$(window).on('load', function () {

  function renderStripeForm(containerSelector, formSelector) {
    $(`#${containerSelector}`).find('#card_info').html('');
    $(`#${containerSelector}`).find('#card_errors').html('');

    var form      = document.getElementById(formSelector);
    var submitBtn = $(`#${formSelector}`).find('[type=submit]')[0];

    const appearance = {
      theme: 'stripe'
    };

    var stripe = Stripe(stripePublicKey, {
      locale: 'de'
    });

    var elements = stripe.elements({appearance});
    var card     = elements.create('card', {
                    style: {
                      base: {
                        fontWeight: '500',
                        fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
                        fontSize: '20px'
                      },
                      invalid: {
                        iconColor: '#AB1515',
                        color: '#AB1515'
                      },
                    }
                  });

    card.addEventListener('change', function(event) {
      var displayError = document.getElementById('card_errors');
      if (event.error) {
        $(submitBtn).prop('disabled', true);
        displayError.textContent = event.error.message;
      } else {
        $(submitBtn).prop('disabled', false);
        displayError.textContent = '';
      }
    });

    card.mount('#card_info');

    form.addEventListener('submit', function(e) {
      e.preventDefault();
      e.stopPropagation();

      if ($('#subscription_terms_and_conditions_true').is(':checked')) {
        $(submitBtn).prop('disabled', true);

        stripe.createToken(card).then(function(result) {
          if (result.error) {
            // Inform that there was an error.
            var errorElement = document.getElementById('card_errors');
            errorElement.textContent = result.error.message;
          } else {
          // Now we submit the form. We also add a hidden input storing 
          // the token. So our back-end can consume it.
            var tokenInput = "<input type='hidden' name='token' id='stripe_token' />";
            $(form).append(tokenInput);
            $('#stripe_token').val(result.token.id);
            $(form).submit();
          }
        });
      } else {
        alert('Bitte bestätige, die AGB von Meet&Match und die produktbezogenen Vertragsinhalte gelesen und verstanden zu haben.');
      }
    });
  }

  $('body').on('click', '.js-subscription-plan-btn', function(e) {
    e.preventDefault();
    var plan = $(this).data('plan');
    $('#subscription_plan_dialog').find('#payment_plan').val(plan);
    $('#subscription_plan_dialog').find('#deduction_cost').text($(this).data('cost'));
    $('#subscription_plan_dialog').find('#total_cost_container').data("total-cost", $(this).data('cost'));

    renderStripeForm('subscription_plan_dialog', 'subscribe_plan_form');

    $('#subscription_plan_dialog').modal('show');
  });

  $('body').on('click', '#event_interested_to_join', function(e) {
    e.preventDefault();
    if ($('#event_terms_and_conditions_checkbox').is(':checked')) {
      renderStripeForm('event_accepting_payment_dialog', 'event_acception_payment_form');
      $('#event_accepting_payment_dialog').modal('show');
    } else {
      alert($(this).data('errorMessage'));
    }
  });

  $('body').on('click', '#date_event_invitation_payment', function(e) {
    e.preventDefault();
    renderStripeForm('date_event_invitation_payment_dialog', 'date_event_invitation_payment_form');
    $('#date_event_invitation_payment_dialog').modal('show');
  });

  $('body').on('click', '#apply_promo_code_element', function(e) {
    e.preventDefault();

    let params = {};
    params['promo_code']   = $('#promo_code_value').val();
    params['total_amount'] = $('#total_cost_container').data('totalCost');

    $.ajax({
      type: 'GET',
      url: '/promo_codes/retrieve_promo_code',
      dataType: 'script',
      data: params
    })
  });
});
