   // import make the file a part of it but require will load the file after all the imports are completed

require("@rails/ujs").start()
// require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

require.context("../images", true);

// JQuery
import $ from 'jquery';
global.$ = global.jQuery = jQuery;

// Moment
import moment from 'moment';
global.moment = moment;

var jQueryBridget = require('jquery-bridget');
var Flickity = require('flickity');
jQueryBridget( 'flickity', Flickity, $ );
import "flickity/dist/flickity.min.css";

import "bootstrap";
import "../stylesheets/application";

require('select2');
require('select2/dist/css/select2.css');
require('../third_party/calentim.min');

import "@fortawesome/fontawesome-free/css/all";
import "../third_party/jquery-birthday-picker";
import "../third_party/css/calentim.min";

$(document).on("turbolinks:before-cache", function() {
  $('.js-select2-dropdown').select2('destroy');
  $('.js-select2-dropdown-without-search-bar').select2('destroy');
});

$(window).on("load", function(){
  $("[data-bs-toggle='tooltip']").tooltip();
  $("[data-bs-toggle='popover']").popover();

  $('.js-select2-dropdown').select2({
      placeholder: " "
  });

  $('.js-select2-dropdown-without-search-bar').select2({
    placeholder: " ",
    minimumResultsForSearch: -1,
    dropdownCssClass: ''
  });

  $('.js-select2-multi-without-search-bar').select2({
    closeOnSelect: false
  });

  $('.js-select2-multi-without-search-bar').on('select2:opening select2:closing', function() {
    var $searchfield = $(this).parent().find('.select2-search__field');
    $searchfield.prop('disabled', true);
  });
});

// service workers
window.addEventListener('load', () => {
  navigator.serviceWorker.register('/service-worker.js').then(registration => {
    window.Notification.requestPermission().then(permission => {    
      if(permission !== 'granted'){
        throw new Error('Permission not granted for Notification');
      }
    });
  }).catch(registrationError => {
    console.log('Service worker registration failed: ', registrationError);
  });
});

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
