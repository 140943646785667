$(window).on('load', function () {

  $('body').on('click', '.js-toggler-element', function() {
    if($(this).hasClass('disabled')) {
      return;
    }
    var item   = $(this).data('togglerItem');
    var parent = $(this).data('parent');
    $(`#${parent}`).find('.js-toggler').attr('class', `toggler js-toggler ${item}`);
    $(`#${parent}`).find('.js-toggler-icon').removeClass('active');
    $(this).find('.js-toggler-icon').addClass('active');
  });

  $('body').on('change', '#impairment', function() {
    if($(this).find(":selected").val() == "Yes") {
      $('#impairment_detail_container').removeClass('collapse');
      $('#impairment_detail').prop('required', true);
    } else {
      $('#impairment_detail_container').addClass('collapse');
      $('#impairment_detail').prop('required', false);
    }
  });

  $('body').on('change', '#sports_others', function() {
    $('#other_sports_field').toggleClass('collapse', !$(this).is(':checked'));
    if ($(this).is(':checked')) {
      $('#other_sports_field').removeClass('collapse');
      $('#other_sports_field').prop('required', true);
    } else {
      $('#other_sports_field').addClass('collapse');
      $('#other_sports_field').prop('required', false);
    }
  });

  $('body').on('change', '#activities_others', function() {
    $('#other_activities_field').toggleClass('collapse', !$(this).is(':checked'));
    if ($(this).is(':checked')) {
      $('#other_activities_field').removeClass('collapse');
      $('#other_activities_field').prop('required', true);
    } else {
      $('#other_activities_field').addClass('collapse');
      $('#other_activities_field').prop('required', false);
    }
  });

  $('body').on('change', '#aware_of_meet_and_match', function() {
    if($(this).find(":selected").val() == "others") {
      $('#other_aware_of_meet_and_match_field').removeClass('collapse');
    } else {
      $('#other_aware_of_meet_and_match_field').addClass('collapse');
    }
  });

  $('body').on('change', '#user_qualification', function() {
    if($(this).find(":selected").val() == "others") {
      $('#user_other_qualification_container').removeClass('collapse');
    } else {
      $('#user_other_qualification_container').addClass('collapse');
    }
  });

  $('body').on('change', '#user_music_preference', function() {
    if($(this).val().indexOf('others') != -1) {
      $('#user_other_music_preference_container').removeClass('collapse');
    } else {
      $('#user_other_music_preference_container').addClass('collapse');
    }
  });

  $('body').on('change', '#user_like_to_watch', function() {
    if($(this).val().indexOf('others') != -1) {
      $('#user_other_like_to_watch_container').removeClass('collapse');
    } else {
      $('#user_other_like_to_watch_container').addClass('collapse');
    }
  });

  $('body').on('change', '#user_trips', function() {
    if($(this).val().indexOf('others') != -1) {
      $('#user_other_trips_container').removeClass('collapse');
    } else {
      $('#user_other_trips_container').addClass('collapse');
    }
  });

  $('body').on('change', '#user_food_preference', function() {
    if($(this).val().indexOf('others') != -1) {
      $('#user_other_food_preference').removeClass('collapse');
    } else {
      $('#user_other_food_preference').addClass('collapse');
    }
  });

  $('body').on('change', '#user_age_filter', function() {
    $('#user_age_filter_options').toggleClass('collapse', !$(this).is(':checked'));
  });

  $('body').on('change', '#user_region_filter', function() {
    $('#user_region_filter_options').toggleClass('collapse', !$(this).is(':checked'));
  });

  $('body').on('click', '#reset_dashboard_filters', function(e) {
    e.preventDefault();
    let activeTab = getActiveTabValue();
    
    setTabValueInFilters(activeTab);

    let link = window.location.href.split("?")[0];
    window.location = `${link}?tab=${activeTab}`;
  });

  $('body').on('click', '.js-membership-element', function() {
    window.location = '/subscription_plans';
  });

  var si = new URL(location).searchParams.get('selectedIndexOnDashboard');
  var selectedIndexOnDashboard = si > 0 ? si : 1;

  window.highPerfectFitsCarouselFunction = function() {
    if ($('#high_perfect_fits_carousel').length > 0) {
      window.highPerfectFitsCarousel = $('#high_perfect_fits_carousel').flickity();
      highPerfectFitsCarousel.flickity('destroy');
      highPerfectFitsCarousel.removeData();
      window.highPerfectFitsCarousel = $('#high_perfect_fits_carousel').flickity({
        pageDots: false,
        imagesLoaded: true,
        contain: true,
        initialIndex: selectedIndexOnDashboard,
        on: {
          settle: function(imageIndex) {
            var maxImages = $('#high_perfect_fits_carousel .flickity-slider').children().length;
            if (maxImages > 4 && imageIndex >= maxImages - 3) {
              sendDashboardRequest('high_perfect_match');
            }
            // setDashboardSelectedIndex(imageIndex);
          }
        }
      });
    }
  }
  highPerfectFitsCarouselFunction();

  window.perfectFitsCarouselFunction = function() {
    if ($('#perfect_fits_carousel').length > 0) {
      window.perfectFitsCarousel = $('#perfect_fits_carousel').flickity();
      perfectFitsCarousel.flickity('destroy');
      perfectFitsCarousel.removeData();
      window.perfectFitsCarousel = $('#perfect_fits_carousel').flickity({
        pageDots: false,
        imagesLoaded: true,
        contain: true,
        initialIndex: selectedIndexOnDashboard,
        on: {
          settle: function(imageIndex) {
            var maxImages = $('#perfect_fits_carousel .flickity-slider').children().length;
            if (maxImages > 4 && imageIndex >= maxImages - 3) {
              sendDashboardRequest('perfect_match');
            }
            // setDashboardSelectedIndex(imageIndex);
          }
        }
      });
    }
  }
  perfectFitsCarouselFunction();

  window.likedByCarouselFunction = function () {
    if ($('#liked_by_carousel').length > 0) {
      window.likedByCarousel = $('#liked_by_carousel').flickity();
      likedByCarousel.flickity('destroy');
      likedByCarousel.removeData();
      window.likedByCarousel = $('#liked_by_carousel').flickity({
        pageDots: false,
        imagesLoaded: true,
        contain: true,
        initialIndex: 1,
        on: {
          settle: function(imageIndex) {
            var maxImages = $('#liked_by_carousel .flickity-slider').children().length;
            if (maxImages > 4 && imageIndex >= maxImages - 3) {
              sendDashboardRequest('liked_by');
            }
            // setDashboardSelectedIndex(imageIndex);
          }
        }
      });
    }
  }
  likedByCarouselFunction();

  window.likedGivenCarouselFunction = function () {
    if ($('#liked_given_carousel').length > 0) {
      window.likedGivenCarousel = $('#liked_given_carousel').flickity();
      likedGivenCarousel.flickity('destroy');
      likedGivenCarousel.removeData();
      window.likedGivenCarousel = $('#liked_given_carousel').flickity({
        pageDots: false,
        imagesLoaded: true,
        contain: true,
        initialIndex: 1,
        on: {
          settle: function(imageIndex) {
            var maxImages = $('#liked_given_carousel .flickity-slider').children().length;
            if (maxImages > 4 && imageIndex >= maxImages - 3) {
              sendDashboardRequest('liked_given');
            }
            // setDashboardSelectedIndex(imageIndex);
          }
        }
      });
    }
  }
  likedGivenCarouselFunction();

  window.viewedByCarouselFunction = function () {
    if ($('#viewed_by_carousel').length > 0) {
      window.viewedByCarousel = $('#viewed_by_carousel').flickity();
      viewedByCarousel.flickity('destroy');
      viewedByCarousel.removeData();
      window.viewedByCarousel = $('#viewed_by_carousel').flickity({
        pageDots: false,
        imagesLoaded: true,
        contain: true,
        initialIndex: 1,
        on: {
          settle: function(imageIndex) {
            var maxImages = $('#viewed_by_carousel .flickity-slider').children().length;
            if (maxImages > 4 && imageIndex >= maxImages - 3) {
              sendDashboardRequest('viewed_by');
            }
            // setDashboardSelectedIndex(imageIndex);
          }
        }
      });
    }
  }
  viewedByCarouselFunction();

  window.hasViewedCarouselFunction = function () {
    if ($('#has_viewed_carousel').length > 0) {
      window.hasViewedCarousel = $('#has_viewed_carousel').flickity();
      hasViewedCarousel.flickity('destroy');
      hasViewedCarousel.removeData();
      window.hasViewedCarousel = $('#has_viewed_carousel').flickity({
        pageDots: false,
        imagesLoaded: true,
        contain: true,
        initialIndex: 1,
        on: {
          settle: function(imageIndex) {
            var maxImages = $('#has_viewed_carousel .flickity-slider').children().length;
            if (maxImages > 4 && imageIndex >= maxImages - 3) {
              sendDashboardRequest('has_viewed');
            }
            // setDashboardSelectedIndex(imageIndex);
          }
        }
      });
    }
  }
  hasViewedCarouselFunction();

  window.allProfilesCarouselFunction = function () {
    if ($('#all_profiles_carousel').length > 0) {
      window.allProfilesCarousel = $('#all_profiles_carousel').flickity();
      allProfilesCarousel.flickity('destroy');
      allProfilesCarousel.removeData();
      window.allProfilesCarousel = $('#all_profiles_carousel').flickity({
        pageDots: false,
        imagesLoaded: true,
        contain: true,
        initialIndex: 1,
        on: {
          settle: function(imageIndex) {
            var maxImages = $('#all_profiles_carousel .flickity-slider').children().length;
            if (maxImages > 4 && imageIndex >= maxImages - 3) {
              sendDashboardRequest('all_profiles');
            }
            // setDashboardSelectedIndex(imageIndex);
          }
        }
      });
    }
  }
  allProfilesCarouselFunction();

  if ($('#user_liked_by_dialog').length > 0) {
    setTimeout(function(){
      $('#user_liked_by_dialog').modal('show');
    }, 1000);
  }

  // hits start
  $('body').on('click', '#dashboard_filters_submit_btn', function(e) {
    e.preventDefault();
    let activeTab = getActiveTabValue();
    
    setTabValueInFilters(activeTab);

    $('#dashboard_filters_form').submit();
  });

  $('body').on('click', '.js-dashboard-nav-link', function() {
    $('.js-dashboard-nav-link').removeClass('active');
    $(this).addClass('active');
    let activeTab = $(this).data('tab'); //getActiveTabValue();
    setTabValueInFilters(activeTab);
    $('#dashboard_filters_form').submit();
  });

  var dashboardTabsSelectTagPreviousValue = '';
  $('body').on('click', '#dashboard_tabs_select_tag', function() {
    dashboardTabsSelectTagPreviousValue = $(this).find(':selected').val();
  }).on('change', '#dashboard_tabs_select_tag', function() {
    if ($(this).find(':selected').data('freemiumRestriction') == true) {
      if (dashboardTabsSelectTagPreviousValue) {
        $(this).val(dashboardTabsSelectTagPreviousValue);
      } else {
        $(this).val($('#dashboard_tabs_select_tag option:first').val()).change();
      }
      alert('Upgraden Sie zuerst auf Premium');
      return;
    }

    let activeTab = $(this).find(':selected').data('tab');
    setTabValueInFilters(activeTab);
    $('#dashboard_filters_form').submit();
  });

  $('body').on('change', '#user_liked', function(){
    var liked  = $(this).is(':checked');
    var userId = $(this).data('userId');
    $('.like-it-container').toggleClass('liked');
    var currentUserId = $(this).data('currentUserId');
    sendUserLikedRequest(userId, currentUserId, liked);
  });

  $( document ).ready(function() {
    if ($('#user_liked').is(':checked')) {
      $('.like-it-container').addClass('liked');
    }

  });
  // hits end
});

window.setDashboardSelectedIndex = function(index) {
  var url = new URL(location);
  url.searchParams.set('selectedIndexOnDashboard', index);
  history.pushState({}, '', url);
}

window.resetDashboardSelectedIndex = function() {
  var url = new URL(location);
  url.searchParams.delete('selectedIndexOnDashboard');
  history.pushState({}, '', url);
}

function sendDashboardRequest(selectedTab, reset = false) {
  let params  = getFilterParams();
  let url     = getURLOnActiveTab(selectedTab);
  let tabInfo = dashboardTabsInfo(selectedTab);

  if (reset) {
    params['refresh_container'] = true;
  } else {
    params['exclude_user_ids'] = $(`${tabInfo['excludeIdsContainer']}`).data('excludeIds');
  }

  $.ajax({
    type: 'GET',
    url: url,
    dataType: 'script',
    data: params
  });
}

function sendUserLikedRequest(userId, currentUserId, liked) {
  $.ajax({
    type: 'PUT',
    url: `/users/${currentUserId}/liked_user`,
    dataType: 'script',
    data: { user_id: userId, liked: liked }
  });
}

function dashboardTabsInfo(tab) {
  let userId = $('#current_user_id').val();
  let info   = {
    dashboard: { link: `/users/${userId}/dashboard`, excludeIdsContainer: '#_' },
    high_perfect_match: { link: `/users/${userId}/load_high_optimal_mates`, excludeIdsContainer: '#high_perfect_fit_exclude_ids' },
    perfect_match: { link: `/users/${userId}/load_optimal_mates`, excludeIdsContainer: '#perfect_fit_exclude_ids' },
    liked_by: { link: `/users/${userId}/load_liked_by`, excludeIdsContainer: '#liked_by_exclude_ids' },
    liked_given: { link: `/users/${userId}/load_liked_given`, excludeIdsContainer: '#liked_given_exclude_ids' },
    viewed_by: { link: `/users/${userId}/load_viewed_by`, excludeIdsContainer: '#viewed_by_exclude_ids' },
    has_viewed: { link: `/users/${userId}/load_has_viewed`, excludeIdsContainer: '#has_viewed_exclude_ids' },
    all_profiles: { link: `/users/${userId}/find_singles`, excludeIdsContainer: '#all_profiles_exclude_ids'}
  }

  return info[tab];
}

function getURLOnActiveTab(selectedTab = '') {
  let activeTab = selectedTab || getActiveTabValue();
  let tabInfo   = dashboardTabsInfo(activeTab);

  return tabInfo['link'];
}

function getFilterParams() {
  let params         = {};
  params["age_from"] = $('#user_filters').find('#age_from').val();
  params["age_to"]   = $('#user_filters').find('#age_to').val();
  params["user_regions"]  = $('.js-user-regions:checked').map(function() { return this.value; }).get();

  return params;
}

function getActiveTabValue() {
  return $('.js-dashboard-tab-pane.active').data('activeTab');
}

function setTabValueInFilters(activeTab) {
  if ($('#dashboard_filters_form #filters_active_tab').length == 0) {
    $('#dashboard_filters_form').append(`<input type="hidden" name="tab" value="${activeTab}" id="filters_active_tab" />`);
  } else {
    $('#filters_active_tab').val(activeTab);
  }
}
