$(window).on('load', function () {
  $('body').on('click', '.js-subscriber-item', function() {
    let roomId = $(this).data('room-id');

    $.ajax({
      method: 'GET',
      url: `/rooms/${roomId}`
    });
  });

  $('body').on('click', '.js-chat-icon-container, .js-chat-icons', function() {
    $.ajax({
      method: 'GET',
      url: '/rooms',
      dataType: 'script'
    });
  });

  $('body').on('click', '#chat_close_icon', function() {
    $('#chats_container').removeClass('opened-state');
    $('#chat_box_container').addClass('d-none');
    $('.js-chat-icon-container').removeClass('d-none');

    $.ajax({
      method: 'GET',
      url: '/rooms/refresh_chats_icon'
    });
  });

  $('body').on('click', '#chat_back_icon', function() {
    $.ajax({
      method: 'GET',
      url: '/rooms'
    });
  });

  $('body').on('click', '#chat_submit_btn', function() {
    let roomId      = $('#room_id').val();
    let messageBody = $('#message_body').val();

    $.ajax({
      method: 'POST',
      url: '/messages',
      data: { message: { room_id: roomId, body: messageBody } }
    });
    $('#message_body').val('');
  });

  $('body').on('change', "#message_body", function(e) {
    if (e.ctrlKey && e.keyCode == 13) {
      let position      = this.selectionEnd;
      this.value        = this.value.substring(0, position) + '<br>' + this.value.substring(position);
      this.selectionEnd = position;
    }
  });
});