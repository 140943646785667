$(window).on('load', function () {
  $('body').on('click', '.js-event-user-element .js-closing-icon', function() {
    $(`#${$(this).data('id')}`).remove();
  });

  $('body').on('click', '.js-add-new-event-user-icon', function() {
    var id        = $(this).data('user-id');
    var avatarUrl = $(this).data('user-avatar-url');

    var element   = `<div class="event-user-element js-event-user-element" id="user_element_${id}">`;
    element      += `${id}`;
    element      += `<i class="closing-icon js-closing-icon fas fa-times" data-id="user_element_${id}"></i>`;
    element      += `<img class="rounded-user-img-sm" src="${avatarUrl}">`;
    element      += `<input type="hidden" name="event[event_users_attributes][${id}][user_id]" id="event_event_users_attributes_${id}_user_id" value="${id}" class="js-event-user"></div>`;

    $('#event_users_container').append(element);
    $(`#searched_user_${id}`).remove();
    alert('User added');
  });

  $('body').on('keyup', '#event_user_search_field', function(e) {
    if (e.which === 13) {
      var query    = $('#event_user_search_field').val();
      var eventId = $('#event_user_search_field').data('event-id');
      var params   = { id: eventId, search_users: { query: query} };
      $.ajax ({
        method: 'GET',
        url: '/events/search_users',
        data: params
      });
    }
  });

  $('body').on('click', '#resend_interest_email', function(e) {
    e.preventDefault();
    var userEmails = [];
    $('.js-event-users-selection:checked').each (function() {
      userEmails.push(this.value);
    });
    if (userEmails.length == 0) {
      alert("Please select Users first");
    } else {
      var eventId = $('#event_id').val();
      var params  = { event: { user_emails: userEmails } };
      $.ajax({
        method: 'POST',
        url: `/events/${eventId}/resend_interest_email`,
        data: params
      });
    }
  });

  $('body').on('click', '#resend_participating_email', function(e) {
    e.preventDefault();
    var userEmails = [];
    $('.js-event-users-selection:checked').each (function() {
      userEmails.push(this.value);
    });
    if (userEmails.length == 0) {
      alert("Please select Users first");
    } else {
      var eventId = $('#event_id').val();
      var params  = { event: { user_emails: userEmails } };
      $.ajax({
        method: 'POST',
        url: `/events/${eventId}/resend_participating_email`,
        data: params
      });
    }
  });
});